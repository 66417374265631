.accordion :global .MuiAccordionDetails-root > div > div:first-child,
.accordion :global .MuiAccordionSummary-expandIconWrapper {
  display: none;
}

.accordion {
  border: 0;
  height: 100%;
  border-color: var(--color-border-light) !important ;
}

.accordion :global .MuiAccordionSummary-root {
  background: transparent !important;
  padding: var(--space-3);
  border-bottom: 1px solid var(--color-border-light);
  pointer-events: none;
}

.accordion :global .MuiAccordionSummary-content {
  margin: 0;
}

.sidebar :global .MuiPaper-root {
  height: calc(100vh - var(--header-height) - var(--footer-height) - 24px);
  overflow: auto;
}

.content {
  background: linear-gradient(-150deg, #ffe9e7, #6aaeff);
  background-size: 200% 200%;
  animation: gradient 15s ease infinite;
  border-radius: 6px;
  padding: var(--space-8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
}

@media (max-width: 900px) {
  .sidebar :global .MuiPaper-root {
    height: 100%;
  }

  .accordion {
    height: auto;
  }

  .accordion :global .MuiAccordionSummary-root {
    pointer-events: auto;
  }

  .accordion :global .MuiAccordionSummary-expandIconWrapper {
    display: block;
  }

  .content {
    padding: var(--space-6);
  }
}

@media (max-width: 600px) {
  .content {
    padding: var(--space-4);
  }
}

@keyframes gradient {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
